<template>
  <div>
    <header-portrate />

    <div class="m-2 pa-2">
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="12">
          <b-input-group class="mt-3">
            <b-form-input placeholder="Start Date" type="date" v-model="sdate"></b-form-input>
            <b-form-input placeholder="End Date"  type="date" v-model="edate"></b-form-input>
            <b-form-input placeholder="Customer Mobile"  v-model="mobile"></b-form-input>
            <b-input-group-append style="border:1px solid #CCC">
              <b-button variant="light" style="font-size:14px;color:#000" @click="getCurrentCards()"><i class="fas fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="12"
          style="display:none;"
          >
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="بحث"
                single-line
                hide-details
                
            ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="12"
          class="text-end"
          >
            <b-button id="multiply-button" :class="`mt-10 multiply-button-`+lang.lalgin" :style="`width:150px;position:absolute;`+lang.lalgin+`:60px;background:transparent;border:none`" v-b-toggle.add_quotation>
            <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_quotation}}</div></b-button>
            <div style="clear:both"></div>
            <br>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="12" :style="`direction:` + lang.dir + `;`">
          <v-data-table
              :headers="headers"
              :items="tablerows"
              :search="search"
              :items-per-page="15"
              :page.sync="page"
              width="100%"
              id="MainTable"
              hide-default-footer
              @page-count="pageCount = $event" 
              fixed-header
          >
            <template v-slot:item="row">
              <tr>
                <td style="width:100px;text-align:center;">{{ row.item.quot_number }}</td>
                <td style="text-align:center;">{{ row.item.quot_date.substr(0,10) }}</td>
                <td style="text-align:center;">{{ row.item.mobile }}</td>
                <td style="text-align:center;" nowrap>{{ row.item.company_name }}</td>
                <td style="text-align:center;">{{ row.item.total }}</td>
                <td style="text-align:center;">{{ row.item.vat }}</td>
                <td style="text-align:center;">{{ row.item.ftotal }}</td>
                <td :style='`border-bottom:2px solid #FFF;text-align:center;color:#`+row.item._fontColor+` !important;background:#`+row.item._statusbgColor'>{{ row.item._status }}</td>
                <td style="text-align:center;">
                  <v-btn style="font-size:0.2em;background:#ffb100;width:100%;color:#FFF;height:25px !important;margin-left:5px;" @click="$router.push({path: '/view-quot/'+row.item.id})">{{lang.view}}</v-btn>
                  <!-- <v-btn v-if="row.item.status > 0" style="font-size:0.2em;background:red;width:50%;color:#FFF;height:25px !important;" @click="cancelInvocie(row.item)" v-b-toggle.view_invoice>ارجاع </v-btn> -->
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">

          <v-container>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                value="10"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-row>
    <add-quotation :cust='custo'></add-quotation>
    </div>
  
    <Footer />
  </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import axios from 'axios';
import AddQuotation from '@/components/addQuotation.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default{
    components: { breadCrumbs, AddQuotation, TabsComp, HeaderPortrate,Footer },
    data() {
        return {
            custo:[],
            sdate: '',
            edate: '',
            mobile: '',
            search: '',
            page: 1,
            pageCount:0,
            
            tablerows: [],
            lstatus: 1
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function() {
          return {
                    text: this.lang.quotations,
                    disabled: true,
                    href: '/quotations',
                }
        },
        tabs: function () {
            
            let tabs = [
                
            ]
            if(this.$store.state.licenseType.crm){
              tabs.push(
                {
                      index:6,name:this.lang.projects,href:'/customers',class:'mytab4',status:1
                  },
              );
            }
            tabs.push(
              {
                    index:0,name:this.lang.customers,href:'/clientList',class:'mytab3',status:1
                }
            );
            tabs.push(
              {
                    index:5,name:this.lang.invoices,href:'/invoicelist',class:'mytab3',status:1
                }
            );
            let cc = '3';
            if(this.$route.path == '/quotations/n/1'){
              cc = '';
            }
            tabs.push({
                    index:1,name:this.lang.new_quotations,href:'/quotations/n/1',class:'mytab'+cc,status:1
                }
            )
            cc = 3
            if(this.$route.path == '/quotations/i/2'){
              cc = '';
            }
            tabs.push(
              {
                    index:2,name:this.lang.invoiced_quots,href:'/quotations/i/2',class:'mytab'+cc,status:2
                }
            )
            cc = 3;
            if(this.$route.path == '/quotations/c/3'){
              cc = '';
            }
            tabs.push(
              {
                    index:3,name:this.lang.canceled_quots,href:'/quotations/c/3',class:'mytab'+cc,status:3
                },
            )
            // tabs.push(
            //   {
            //         index:3,name:this.lang.car_report,href:'/car-report',class:'mytab3',status:3
            //     },
            // )
            if(this.$store.state.licenseType.families){
                tabs.push({
                    index:3,name:this.lang.sent_requests,href:'/vendor-sents',class:'mytab3',status:3
                })
                tabs.push({
                    index:3,name:this.lang.payment_requests,href:'/vendor-prequests',class:'mytab3',status:3
                })
            }
            return tabs;
        },
        headers: function () {
            let headers = [];
            headers.push({
              text: this.lang.quotation_no, align: 'center',
              filterable: false, value: 'quot_number',
              sortable: false,
            });
            headers.push({
              text: this.lang.quot_date, align: 'center',
              filterable: false, value: 'quot_date',
              sortable: false,
            });
            headers.push({
              text: this.lang.mobile, align: 'center',
              filterable: false, value: 'mobile',
              sortable: false,
            });
            headers.push({
              text: this.lang.company_name, align: 'center',
              filterable: false, value: 'company_name',
              sortable: false,
            });
            headers.push({
              text: this.lang.total, align: 'center',
              filterable: false, value: 'total',
              sortable: false,
            });
            headers.push({
              text: this.lang.vat, align: 'center',
              filterable: false, value: 'vat',
              sortable: false,
            });
            headers.push({
              text: this.lang.ftotal, align: 'center',
              filterable: false, value: 'ftotal',
              sortable: false,
            });
            headers.push({
              text: this.lang.status, align: 'center',
              filterable: false, value: '_status',
              sortable: false,
            });
            headers.push({
              text: this.lang.action, align: 'center',
              filterable: false,
              sortable: false,
            });
            return headers;
        }
    },
    methods: {
        gParams(){
            if(String(this.$route.params.status) == 'undefined'){
                this.$route.params.status = 1;
            }
        },
        getQuotations(status) {
            this.lstatus = status;
            const post = new FormData();
            post.append('type', "getQuotations");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[sdate]',this.sdate)
            post.append('data[edate]',this.edate)
            post.append('data[mobile]',this.mobile)
            post.append('data[status]',status);

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.tablerows = res.results.data;
            })

        }
    },
    created() {
        this.getQuotations(1);
    },
}
</script>
<style>
.mytab{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mytab2{
  width:100%;
  background:rgb(4, 112, 155) !important;
  font-size:.8rem;
  color:#FFF;
  border:0px solid #fff;
}
.mytab4{
  width:100%;
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
.mytab3{
  width:100%;
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
.mytab1{
  width:100%;
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
.mytab5{
  width:100%;
  font-size:.8rem;
  background:#339cfe !important;
  border:0px solid #fff;
}
.mytab6{
  width:100%;
  font-size:.8rem;
  background:#fe3344 !important;
  border:0px solid #fff;
}
input[type=date]:invalid::-webkit-datetime-edit {
    color: #000;
    font-weight: bold;
}
</style>